* {
  font-family: "Rubik", sans-serif;
}

.city-selector {
  position: fixed;
  bottom: 5%;
  right: 34%;
  background-color: white;
  padding: 10px;
  border-radius: 6px;
}

.city-meter {
  position: fixed;
  display: flex;
  top: 0%;
  left: 1%;
  padding: 10px;
  border-radius: 6px;
}

.delivery-boys-meter {
  position: fixed;
  display: flex;
  top: 8%;
  left: 1%;
  padding: 10px;
  border-radius: 6px;
}
.footer-button {
  background-color: #1e79dc;
  padding: 1px 16px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: between;
}
.footer-button:hover {
  background-color: #0079fd;
}
.footer-container {
  padding: 5px;
  position: fixed;
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  height: 6vh;
  bottom: 0px;
  display: flex;
  align-items: center;
  color: white;
}

.header-button {
  background-color: #1e79dc;
  padding: 1px 16px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: 'space-between';
  color: #fff;
  gap: 3px;
  position: relative;

}
/* .header-button {
  position: relative;
  margin-right: 11px;
  font-size: 12px;
  padding: 3px;
  background-color: #134780;
  border-radius: 4px;
  color: white;
} */
.header-button:hover {
  background-color: #477fbc;
}
.header-button:active {
  background-color: #04172c;
}

.text-muted {
  color: grey;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.dg-selector {
  position: fixed;
  bottom: 1px;
  left: 1vw;
  background-color: white;
  width: 40vw;
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
  -webkit-filter: drop-shadow(2px 4px 6px black);
  filter: drop-shadow(2px 4px 6px black);
}

.rounded_image {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.font-b {
  font-weight: bold;
}

.selected-tab {
  color: white;
  text-align: center;
  width: 50%;
  padding: 10px;
  font-weight: bold;
  border-radius: 6px;
  position: relative;
  background-color: #3366fecc;
}

.normal-tab {
  text-align: center;
  width: 50%;
  padding: 10px;
  position: relative;
}

.vertical_scroll {
  overflow-y: scroll;
  height: 72%;
  padding: 8px;
}

.card_tab {
  margin-top: 5px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #e0e0e0b8;
  position: relative;
}

#markerLayer img {
  border: 2px solid red !important;
  width: 85% !important;
  height: 90% !important;
  border-radius: 5px;
}

.hidden {
  display: none;
}

.card-hype {
  padding: 9px;
  background-color: aliceblue;
  border-radius: 5px;
  margin-bottom: 8px;
}

.error-div {
  width: 100%;
  margin: auto;
  padding: 10%;
  height: 100vh;
  background-color: #848484;
  color: white;
}

.error-inner-div {
  text-align: center;
  background-color: white;
  width: 43%;
  margin: auto;
  border-radius: 10px;
  padding: 20px;
  color: black;
  filter: drop-shadow(2px 4px 6px black);
}

.progress {
  background-color: rgb(62, 122, 235);
  height: 10px;
  border-radius: 1rem;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.progress-div {
  background-color: rgb(233, 233, 233);
  border-radius: 0.5rem;
}

.auto-assign-div {
  background-color: #52bd95;
  border-radius: 0px 0px 4px 4px;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: 700;
  display: flex;
  color: white;
}

.auto-assign-ongoing-div {
  background-color: #485bca;
  border-radius: 0px 0px 4px 4px;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: 700;
  display: flex;
  color: white;
}

.auto-assign-div-failed {
  background-color: #f73b3bd7;
  border-radius: 0px 0rgb (243, 77, 77) 4px;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: 700;
  display: flex;
  color: white;
}

.settings-selector {
  position: fixed;
  background-color: #ffffff;
  padding: 10px;
  width: 13%;
  border-radius: 6px;
  top: 9%;
  right: 6%;
  z-index: 999999;
  filter: drop-shadow(0px 2px 5px #00000052);
}

.css-1fulyla {
  z-index: 9999999 !important;
}
.custom-collapse-panel .ant-collapse-arrow > .anticon {
  color: #fff; /* Change to your desired arrow color */
}
.custom-main-collapse .ant-collapse-item {
  border-bottom: none !important;
}

.custom-main-collapse .ant-collapse-header {
  border: none !important;
}

.custom-main-collapse .ant-collapse-content {
  border-top: none !important;
}

.fleet_btn {
  background-color: #134780;
}